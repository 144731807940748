import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { backendUrl } from "../../apiFetch";
import "./LandPage.scss";
import pointArrow from "../img/point-arrow2.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from "react-responsive-carousel";

function LandPage(data) {
  return (
    <div className="landpage" id="landpage">
      <div className="landpage-container">
        <div className="landpage-container-left">
          <div className="landpage-container-left-bigtext">
            <span className="landpage-container-left-bigtext-1">
              {data.data.allStrapiLandPage.nodes[0].TitlePart1}{" "}
            </span>
            <span>{data.data.allStrapiLandPage.nodes[0].TitlePart2}</span>
          </div>
          <div className="landpage-container-left-smalltext">
            {data.data.allStrapiLandPage.nodes[0].SubTitle}
          </div>
          <div className="landpage-container-left-buttons">
            <a
              href="/objednavka"
              className="button-hover-effect landpage-container-left-buttons-discover"
              rel="nofollow"
            >
              {data.data.allStrapiLandPage.nodes[0].ExtraButton}
            </a>
            <a
              href="/go?pref=new&ref=hp"
              className="button-hover-effect landpage-container-left-buttons-free"
              rel="nofollow"
            >
              Vímto zdarma
              <div className="landpage-container-left-buttons-free-float">
                A bez registrace
                <img
                  src={pointArrow}
                  className="landpage-container-left-buttons-free-float-arrow"
                  alt="Arrow pointing up"
                />
              </div>
            </a>
          </div>
        </div>
        <div className="landpage-container-right">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            interval={7000}
            renderThumbs={(children) => {
              return data.data.allStrapiCarousels.nodes.map((object, i) => (
                <div key={i}>{object.Text}</div>
              ));
            }}
            className="landpage-container-right-carousel"
          >
            {data.data.allStrapiCarousels.nodes.map((object, i) => (
              <div key={i}>
                <img alt="" src={backendUrl(object.Image.url, false)} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query LandPageQuery {
        allStrapiCarousels {
          nodes {
            Text
            Image {
              id
              url
            }
            id
          }
        }
        allStrapiLandPage {
          nodes {
            ExtraButton
            SubTitle
            TitlePart1
            TitlePart2
            id
          }
        }
      }
    `}
    render={(data) => <LandPage data={data} />}
  />
);
