import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import Markdown from "marked-react";
import AnimateHeight from "react-animate-height";
import "./FAQ.scss";
import arrowUp from "../img/arrow-up.svg";
import arrowDown from "../img/arrow-down.svg";

function QuestionBox(props) {
  const [openBox, setOpenBox] = useState(false);
  return (
    <div className="faq-container-questions-box">
      <div
        onClick={() => {
          setOpenBox(!openBox);
        }}
        className="faq-container-questions-box-container"
      >
        <div
          className={`faq-container-questions-box-container-title faq-container-questions-box-container-title-${
            openBox ? "shown" : "hidden"
          }`}
        >
          {props.title}
        </div>
        <div>
          {openBox ? (
            <img src={arrowUp} alt="Šipka nahoru" />
          ) : (
            <img src={arrowDown} alt="Šipka dolu" />
          )}
        </div>
      </div>
      <AnimateHeight height={openBox ? "auto" : 0} duration={500}>
        <div className="faq-container-questions-box-text">
          <Markdown>{props.text}</Markdown>
        </div>
      </AnimateHeight>
    </div>
  );
}

function FAQ(data) {
  return (
    <div className="faq-container" id="faq">
      <div className="faq-container-text">
        {data.data.allStrapiFaqPage.edges[0].node.Title}
      </div>
      <div className="faq-container-headline">
        {data.data.allStrapiFaqPage.edges[0].node.SubTitle}
      </div>
      <div className="faq-container-questions">
        {data.data.allStrapiFaqs.edges.map((object, i) => (
          <QuestionBox
            key={i}
            title={object.node.Title}
            text={object.node.Text}
          />
        ))}
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query FAQQuery {
        allStrapiFaqPage {
          edges {
            node {
              Title
              SubTitle
              id
            }
          }
        }
        allStrapiFaqs {
          edges {
            node {
              id
              Title
              Text
            }
          }
        }
      }
    `}
    render={(data) => <FAQ data={data} />}
  />
);
