import React from "react";
import { graphql, StaticQuery } from "gatsby";
import "./Reviews.scss";
import stars from "../img/stars.svg";
import pointArrow from "../img/point-arrow.svg";

function Reviews(data) {
  return (
    <div className="reviews" id="reviews">
      <div className="reviews-container">
        <div className="reviews-container-text">
          {data.data.allStrapiReviewsPage.nodes[0].Title}
        </div>
        <div className="reviews-container-headline">
          {data.data.allStrapiReviewsPage.nodes[0].SubTitle}
        </div>
        <div className="reviews-container-boxlist">
          {data.data.allStrapiReviews.nodes.map((object, i) => (
            <div key={i}>
              <div className="reviews-container-boxlist-box">
                {Array(object.Stars)
                  .fill(1)
                  .map((obj, i2) => (
                    <img
                      src={stars}
                      key={i2}
                      className="reviews-container-boxlist-box-stars"
                      alt="namespace"
                    />
                  ))}
                <div className="reviews-container-boxlist-box-text">
                  {object.Text}
                </div>
                <div className="reviews-container-boxlist-box-author">
                  {object.Author}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="reviews-container-buttons">
          <a
            href="/objednavka"
            className="button-hover-effect reviews-container-buttons-free"
            rel="nofollow"
          >
            Vímto plná verze
            <div className="reviews-container-buttons-free-float">
              <img
                src={pointArrow}
                className="reviews-container-buttons-free-float-arrow"
                alt="Arrow pointing up"
              />
              Chci to objednat hned
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query ReviewsQuery {
        allStrapiReviewsPage {
          nodes {
            SubTitle
            Title
            id
          }
        }
        allStrapiReviews {
          nodes {
            Author
            Stars
            Text
            id
          }
        }
      }
    `}
    render={(data) => <Reviews data={data} />}
  />
);
