import * as React from "react";
import "./shared.scss";
import Header from "../components/Header";
import LandPage from "../components/LandPage";
import Info from "../components/Info";
import Reviews from "../components/Reviews";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const pageTitle = "Vítejte - Vímto";

const IndexPage = () => {
  useEffect(() => {
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  });

  return (
    <div className="App">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-1070604381"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-1070604381');`}
        </script>
        <meta
          property="og:image"
          content={process.env.MAIN_URL + "index.png"}
        />
        <meta
          name="description"
          content="Vímto je webová aplikace, která vám usnadní aktivní zapojení všech žáků do výuky. Využijete ji při distanční i prezenční výuce, a to napříč předměty i stupni vzdělávání."
        ></meta>
      </Helmet>
      <div className="App-head">
        <Header></Header>
        <LandPage></LandPage>
      </div>
      <Info></Info>
      <Reviews></Reviews>
      <Pricing></Pricing>
      <FAQ></FAQ>
      <Footer></Footer>
    </div>
  );
};

export default IndexPage;
