import React, { useState, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import "./Pricing.scss";
import checkBad from "../img/check-bad.svg";
import checkGood from "../img/check-good.svg";
import buttonMinus from "../img/button-minus.svg";
import buttonPlus from "../img/button-plus.svg";

function Pricing(data) {
  const [selectedStudents, setSelectedStudents] = useState(100);
  const [actualPrice, setActualPrice] = useState(1000);

  useEffect(() => {
    let price = selectedStudents * 10;
    if (price < 1000) {
      price = 1000;
    }
    if (price > 13000) {
      price = 13000;
    }
    const priceString = "" + price;
    setActualPrice(priceString.slice(0, -3) + " " + priceString.slice(-3));
  }, [selectedStudents]);

  return (
    <div className="pricing-container" id="pricing">
      <div className="pricing-container-text">
        {data.data.allStrapiPricePage.nodes[0].Title}
      </div>
      <div className="pricing-container-headline">
        {data.data.allStrapiPricePage.nodes[0].SubTitle}
      </div>
      <div className="pricing-container-tiers">
        <div className="pricing-container-tiers-box">
          <div className="pricing-container-tiers-box-header pricing-container-tiers-box-header-left">
            <div className="pricing-container-tiers-box-header-top">
              <div className="pricing-container-tiers-box-header-top-text pricing-container-tiers-box-header-top-text-left">
                {data.data.allStrapiPricePage.nodes[0].FreeTitle}
              </div>
            </div>
            <div className="pricing-container-tiers-box-header-top-description pricing-container-tiers-box-header-top-description-left">
              {data.data.allStrapiPricePage.nodes[0].FreeSubTitle}
            </div>
          </div>
          <div className="pricing-container-tiers-box-content">
            <div className="pricing-container-tiers-box-content-size">
              <div className="pricing-container-tiers-box-price">
                {data.data.allStrapiPricePage.nodes[0].FreePrice}
              </div>
              <a
                href="/go?pref=new&ref=hp"
                className="button-hover-effect pricing-container-tiers-box-try-button"
                rel="nofollow"
              >
                {data.data.allStrapiPricePage.nodes[0].FreeButton}
              </a>
            </div>
            <div className="pricing-container-tiers-box-content-list">
              {data.data.allStrapiPriceFreeLists.nodes.map((object, i) => (
                <div
                  key={i}
                  className="pricing-container-tiers-box-content-list-row"
                >
                  {object.CrossIcon === true ? (
                    <img
                      className="pricing-container-tiers-box-content-list-check"
                      src={checkBad}
                      alt=""
                    />
                  ) : (
                    <img
                      className="pricing-container-tiers-box-content-list-check"
                      src={checkGood}
                      alt=""
                    />
                  )}
                  {object.Text}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pricing-container-tiers-box">
          <div className="pricing-container-tiers-box-header">
            <div className="pricing-container-tiers-box-header-top">
              <div className="pricing-container-tiers-box-header-top-text">
                {data.data.allStrapiPricePage.nodes[0].PaidTitle}
              </div>
              <div className="pricing-container-tiers-box-header-top-version">
                <p>Plná verze</p>
              </div>
            </div>
            <div className="pricing-container-tiers-box-header-top-description">
              {data.data.allStrapiPricePage.nodes[0].PaidSubtitle}
            </div>
          </div>
          <div className="pricing-container-tiers-box-content">
            <div className="pricing-container-tiers-box-content-size">
              <div className="pricing-container-tiers-box-content-price">
                {actualPrice} Kč
              </div>
              <div className="pricing-container-tiers-box-content-count">
                <div className="pricing-container-tiers-box-content-count-text">
                  Počet žáků školy
                </div>
                <div className="pricing-container-tiers-box-content-count-right">
                  <div
                    onClick={() => {
                      if (selectedStudents - 10 >= 10) {
                        setSelectedStudents(selectedStudents - 10);
                      }
                    }}
                    className={`button-hover-effect pricing-container-tiers-box-content-count-right-button pricing-container-tiers-box-content-count-right-button-${
                      selectedStudents <= 10 ? "disabled" : ""
                    }`}
                  >
                    <img
                      className={`pricing-container-tiers-box-content-count-right-button-icon pricing-container-tiers-box-content-count-right-button-icon-${
                        selectedStudents <= 10 ? "disabled" : ""
                      }`}
                      src={buttonMinus}
                      alt=""
                    />
                  </div>
                  <div className="pricing-container-tiers-box-content-count-right-number">
                    <input
                      maxLength="4"
                      min="10"
                      max="2000"
                      step="10"
                      type="text"
                      pattern="[0-9]*"
                      onChange={(event) => {
                        let inputNumber = parseInt(event.target.value, 10);
                        if (isNaN(inputNumber)) {
                          inputNumber = "";
                        } else if (inputNumber < 1) {
                          inputNumber = 1;
                        } else if (inputNumber > 2000) {
                          inputNumber = 2000;
                        }
                        setSelectedStudents(inputNumber);
                      }}
                      value={selectedStudents}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (selectedStudents + 10 <= 2000) {
                        setSelectedStudents(selectedStudents + 10);
                      }
                    }}
                    className={`button-hover-effect pricing-container-tiers-box-content-count-right-button pricing-container-tiers-box-content-count-right-button-${
                      selectedStudents >= 2000 ? "disabled" : ""
                    }`}
                  >
                    <img
                      className={`pricing-container-tiers-box-content-count-right-button-icon pricing-container-tiers-box-content-count-right-button-icon-${
                        selectedStudents >= 2000 ? "disabled" : ""
                      }`}
                      src={buttonPlus}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <a
                href={"/objednavka?zaci=" + selectedStudents}
                className="button-hover-effect pricing-container-tiers-box-content-continue-button"
                rel="nofollow"
              >
                {data.data.allStrapiPricePage.nodes[0].PaidButton}
              </a>
            </div>
            <div className="pricing-container-tiers-box-content-list">
              {data.data.allStrapiPricePaidLists.nodes.map((object, i) => (
                <div
                  key={i}
                  className="pricing-container-tiers-box-content-list-row"
                >
                  {object.CrossIcon === true ? (
                    <img
                      className="pricing-container-tiers-box-content-list-check"
                      src={checkBad}
                      alt=""
                    />
                  ) : (
                    <img
                      className="pricing-container-tiers-box-content-list-check"
                      src={checkGood}
                      alt=""
                    />
                  )}
                  {object.Text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query PricingQuery {
        allStrapiPricePage {
          nodes {
            FreeButton
            FreePrice
            FreeSubTitle
            PaidButton
            PaidSubtitle
            FreeTitle
            PaidTitle
            SubTitle
            Title
            id
          }
        }
        allStrapiPriceFreeLists {
          nodes {
            CrossIcon
            Text
            id
          }
        }
        allStrapiPricePaidLists {
          nodes {
            Text
            id
          }
        }
      }
    `}
    render={(data) => <Pricing data={data} />}
  />
);
