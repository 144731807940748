import React from "react";
import { backendUrl } from "../../apiFetch";
import { graphql, StaticQuery } from "gatsby";
import Markdown from "marked-react";
import "./Info.scss";

function Info(data) {
  return (
    <div className="info" id="info">
      <div className="info-container">
        <div className="info-container-title">
          <div className="info-container-title-text">
            {data.data.allStrapiInfoPage.nodes[0].Title}
          </div>
          <div className="info-container-title-headline">
            {data.data.allStrapiInfoPage.nodes[0].SubTitle}
          </div>
        </div>
        {data.data.allStrapiInfoBoxes.nodes.map((object, i) => (
          <div key={i}>
            <div className="info-container-box">
              <div className="info-container-box-content">
                <div className="info-container-box-content-number">
                  0{i + 1}
                </div>
                <div className="info-container-box-content-headline">
                  {object.Title}
                </div>
                <div className="info-container-box-content-text">
                  <Markdown gfm={false} baseURL={backendUrl()}>
                    {object.Text}
                  </Markdown>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query InfoQuery {
        allStrapiInfoPage {
          nodes {
            SubTitle
            Title
          }
        }
        allStrapiInfoBoxes {
          nodes {
            Text
            Title
          }
        }
      }
    `}
    render={(data) => <Info data={data} />}
  />
);
